:root {
    --app-height: 100vh;
}

*,
*::before,
*::after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
}

body {
    font-size: 1.6rem;
    color: #212243;
    line-height: 1.3;
    background-color: #f8f8f8;
    /* make 1 rem = 10px.*/
    font-size: 62.5%;
    min-height: 100%;
    font-family: 'Montserrat', sans-serif, Arial, Helvetica;
}

html,
body {
    -webkit-tap-highlight-color: transparent;
    height: 100%;
}

img {
    max-width: 100%;
}

#app {
    background-color: white;
    min-height: 100vh;
}

a {
    text-decoration: none;
}

button,
button:active,
button:focus,
button:focus-within {
    outline: none;
}
